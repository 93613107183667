import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ContactFormModal from '../Contactus/ContactFormModal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Keyboard, Autoplay } from 'swiper/modules';
import SwiperCore from 'swiper/core';

SwiperCore.use([Autoplay, Mousewheel, Keyboard]);

const OngoingPropertyBanner = () => {
    const propertyData = useSelector(state => state?.property?.propertyData);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    return (
        <>
            <div className='PropertyBanner-main-parent'>
                <div className='PropertyBanner-sub-parent'>
                    <div className='Banner-main-image'>
                        <img src={propertyData?.img1} alt="" />
                        {/* <Swiper
                                        // spaceBetween={50}
                                        slidesPerView={1}
                                        loop={true}
                                        // pagination={{ clickable: true }}
                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Autoplay, Mousewheel, Keyboard]}
                                        style={{ height: '500px' }}
                                        // className='banner-swiper'
                                    >
                                        <SwiperSlide>
                                            <img src="/h1.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/h2.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/h3.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/skyforest.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/estate2.jpg" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/grvityaranya.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/smera-garden.jpg" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/grvityaranyaupdate.jpeg" alt="" />
                                        </SwiperSlide>
                                    </Swiper> */}
                        <div className='Banner-badge-absolute' role='button' onClick={showModal}>
                            <img src="/bannerBadgemain.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <ContactFormModal
                        isModalOpen={isModalOpen}
                        handleOk={handleOk}
                        setIsModalOpen={setIsModalOpen}
                        handleCancel={handleCancel}
                    />

        </>
    );
};

export default OngoingPropertyBanner;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ContactFormModal from './Contactus/ContactFormModal';
import VideoModal from './VideoModal'; // Import the VideoModal component

const HomeAboutus = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false); // State for video modal
    const [videoUrl, setVideoUrl] = useState(''); // State for video modal
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleVideoOk = () => {
        setIsVideoModalOpen(false);
        setVideoUrl('');
    };
    const handleVideoCancel = () => {
        setIsVideoModalOpen(false);
        setVideoUrl('');
    };
    const [counters, setCounters] = useState({
        teamQuality: 0,
        yearsExperience: 0,
        unitsQuality: 0
    });

    useEffect(() => {
        const increments = { teamQuality: 50, yearsExperience: 15, unitsQuality: 1000 };
        const duration = 5000; // Duration of the counter animation in milliseconds
        const interval = 10; // Interval of the counter update in milliseconds

        // Object to store interval IDs
        const intervalIds = {};

        // Function to start counter animations
        const startCountersAnimation = () => {
            Object.keys(increments).forEach(key => {
                const increment = increments[key] / (duration / interval);
                intervalIds[key] = setInterval(() => {
                    setCounters(prevCounters => {
                        if (prevCounters[key] < increments[key]) {
                            return { ...prevCounters, [key]: prevCounters[key] + increment };
                        } else {
                            clearInterval(intervalIds[key]);
                            return { ...prevCounters, [key]: increments[key] };
                        }
                    });
                }, interval);
            });
        };

        // Trigger animation when component mounts
        startCountersAnimation();

        // Clean up intervals on component unmount
        return () => {
            Object.keys(intervalIds).forEach(key => {
                clearInterval(intervalIds[key]);
            });
        };
    }, []);

    const handleVideoClick = () => {
        setIsVideoModalOpen(true); // Show video modal on click
        setVideoUrl('https://www.youtube.com/embed/VKOJeLyMsiU')
    };

    return (
        <div className='HomeAboutus-parent'>
            <div className="container">
                <div className='HomeAboutus-sub-parent'>
                    <div className="row justify-content-between">
                        <div className="col-md-6 col-lg-5">
                            <div className='HomeAboutus-title'>
                                <div className='HomeAboutus-title-sub'>
                                    <div className='HomeAboutus-image'>
                                        <img src="/homeabout.png" alt="" />
                                    </div>
                                    <h3>About Us</h3>
                                </div>
                                <div className='HomeAboutus-title-text'>
                                    <p>We take great pride in ensuring the satisfaction of our customers. That's why we proudly guarantee the quality and reliability of our products.</p>
                                </div>
                                <Link to="#" onClick={showModal}>
                                    <button className='text-black'>Get Started </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='HomeAboutus-title-sub-right HomeAboutus-title-text'>
                                <h1>A Quality Lifestyle Is Incomplete Without A Rooted Experience</h1>
                                <p className='HomeAboutus-main-text'>We believe that the most engaging experiences have one thing in common— a reflective understanding of space. Creating an environment that exudes your warmth, we are keen on designing spaces that see, support and speak of your own novel ideas of a living that feels grounded in your dreams.</p>
                                <div className="row">
                                    <div className="col-4">
                                        <div className='HomeAboutus-counter-sub'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <h1>{Math.ceil(counters.teamQuality)}</h1>
                                                <span>+</span>
                                            </div>
                                            <p className='years'>Team for quality</p>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className='HomeAboutus-counter-sub'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <h1>{Math.ceil(counters.yearsExperience)}</h1>
                                                <span>+</span>
                                            </div>
                                            <p className='years'>Years of Experience</p>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className='HomeAboutus-counter-sub'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <h1>{Math.ceil(counters.unitsQuality)}</h1>
                                                <span>+</span>
                                            </div>
                                            <p className='years'>Units of Excellence</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='DreamHoiuseVideoSection'>
                        <div className='DreamHouseVideoSection-video' style={{ position: 'relative' }}>
                            <div className='AvsoluteImage-video' onClick={handleVideoClick} style={{ cursor: 'pointer' }}>
                                <img src="/thumbnail-homepage.png" alt="" />
                                <div className='play-pause-button'>
                                    <img src="/videoplayicon.png" alt="" />
                                </div>
                            </div>
                            <h2>PERFECT SANCTUARY</h2>
                        </div>
                    </div>
                </div>
            </div>
            <ContactFormModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
            <VideoModal
                isModalOpen={isVideoModalOpen}
                handleOk={handleVideoOk}
                handleCancel={handleVideoCancel}
                videoUrl={videoUrl}
            />
        </div>
    );
}

export default HomeAboutus;

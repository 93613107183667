
import React, {useState, useRef } from 'react'
import HomeNavbar from '../HomeNavbar';
import "../FaqComponents/faq.css";
import Footer from '../Footer';
import { Helmet } from 'react-helmet-async';
import Testimonials from '../Testimonials';
import OurStrengths from '../OurStrengths';
import CareerForm from './CareerForm';

const Careers = () => {
    const [isFormOpen, setFormOpen] = useState(false);
    
    const handleOpenForm = () => setFormOpen(true);
    const handleCloseForm = () => setFormOpen(false);    

    const jobCardsRef = useRef(null);

    const scrollToJobs = () => {
        if (jobCardsRef.current) {
            jobCardsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <>
        <Helmet>
      <title>Gravity Homes - Career</title>
      <meta name='description' content='Gravity Homes Leading Villa, Villaments And Apartment Developers In Bangalore Offers Your 3 5 And 4 BHK Villas And Independent Homes With Premium Class Amenities' data-rh="true" />
    </Helmet>
            <div className="Investfaq-parent pb-0">
                <div className='Investfaq-subparent'>
                    <HomeNavbar />

                    <div className='ProjectsBanner-main-parent bg-white '>
  <div className='ProjectsBanner-sub-parent flex flex-col items-center justify-center '>
    <div className='ProjectsBanner-bg'>
      <div className='ProjectsBanner-content'>
        <h1 className='text-4xl font-medium text-center text-gray-800'>
          Careers
        </h1>
      </div>
    </div>
    <div
      className=' flex flex-col items-center justify-center text-center p-8 '
      style={{ backgroundImage: `url('/ProjectsBackground.svg')`, backgroundSize: 'cover', backgroundPosition: 'center' ,height:"fit-content"}}
    >
      <h2 className='text-lg md:text-xl lg:text-2xl text-gray-600 mb-4  text-center justify-center mx-auto'>
        Join Our Team
      </h2>
      <h1 className='text-2xl md:text-3xl lg:text-4xl font-medium text-gray-900 mb-6'>
        Build a Rewarding Career with Innovation, Growth, and Opportunities to Excel!
      </h1>
      <h3 className='text-base md:text-lg lg:text-xl text-gray-700 mb-6'>
        Explore career opportunities at Gravity Homes and join us in creating innovative, 
        sustainable luxury living spaces that make a global impact.
      </h3>
      <button
        onClick={scrollToJobs}
        className='bg-yellow-700 text-white px-6 py-3 rounded-full font-medium hover:bg-yellow-600 transition-colors duration-300'
      >
        View All Jobs
      </button>
    </div>
  </div>
</div>

                </div>


                <div ref={jobCardsRef} className='bg-green p-4'>
            <p className='text-white text-center text-2xl md:text-3xl lg:text-4xl font-medium p-6'>
                Career Opportunities for You!
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-4 pb-5">
                {[
                    {
                        title: 'Sales Manager',
                        location: 'Bangalore',
                        description: 'Lead sales efforts, drive revenue, and manage client relationships while aligning with sustainability goals.',
                        buttonLabel: 'Apply Now',
                    },
                    {
                        title: 'Project Manager',
                        location: 'Bangalore',
                        description: 'Oversee project execution, ensuring timely and budget-compliant delivery with a focus on eco-conscious practices.',
                        buttonLabel: 'Apply Now',
                    },
                    {
                        title: 'Construction Site Supervisor',
                        location: 'Bangalore',
                        description: 'Manage site operations, coordinate with subcontractors, and ensure safety and environmental compliance.',
                        buttonLabel: 'Apply Now',
                    },
                    {
                        title: 'Marketing and Communications Manager',
                        location: 'Bangalore',
                        description: 'Create and execute marketing strategies, manage PR, and maintain brand messaging that emphasizes sustainability and luxury.',
                        buttonLabel: 'Apply Now',
                    },
                ].map((job, index) => (
                    <div 
                        key={index} 
                        className="bg-white p-6 rounded-lg shadow-lg flex flex-col h-full"
                    >
                        <h2 className="text-xl font-bold text-gray-800 mb-2">{job.title}</h2>
                        <p className="text-gray-600 mb-2 text-sm font-medium">{job.location}</p>
                        <p className="text-gray-600 mb-4 text-base flex-grow">{job.description}</p>
                        <button 
                            className="bg-yellow-600 text-white px-4 py-2 rounded-lg font-semibold hover:bg-yellow-700 transition-colors duration-300 w-full mt-auto"
                            onClick={handleOpenForm}
                        >
                            {job.buttonLabel}
                        </button>
                    </div>
                ))}
            </div>
            {isFormOpen && <CareerForm onClose={handleCloseForm} />}
        </div>
        <div className='bg-white py-10'>
    <div className='max-w-6xl mx-auto px-4 text-center'>
        <p className='text-gray-900 text-2xl md:text-3xl lg:text-4xl font-medium '>
            Stay Connected with Us!
        </p>
        <p className='text-gray-600 text-lg '>
            Follow us on social media to get the latest updates on job openings, company news, and career tips.
        </p>
    </div>
</div>
            </div>
            <Footer />
        </>
    )
}
export default Careers;
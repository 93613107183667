import React, { useState } from 'react'

const FaqSection = () => {
    const [openIndex, setOpenIndex] = useState(null);


    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    const itemsToShow = [ {
        question: "What is Gravity history,mission and vision?",
        answer: "With over a decade of experience and some credible projects to our name, Gravity is committed to provide you more than just a living space but homes that touch lives and stay with you for a lifetime. Our mission is to empower your lifestyle choices with meaningful and quality living that enriches your lives. We design with a vision to bring a distinct community life where your home has it all— a communion with nature, with people, and your own mindful private comfort."
    }, {
        question: "How are you committed to the community and environment?",
        answer: "With each project, we incorporate sought-after sustainable and natural materials projects, sourcing everything from Versatile Indian Stones to Renewable Materials from across the globe to inspire the changing perceptions of luxury. From Energy-Efficient Biophilic Architecture to Serene Pond Ecosystems, Rainwater Recharge Basins, Groundwater Replenishment and Eco-Friendly, Light-Diffusing Designs, we make sure to minimise our environmental footprint and embrace nature in the best ways before you move in and embrace your home!"
    }, {
        question: "Where are your projects located?",
        answer: "Find Gravity homes located all over, from Talaghattapura, Kanakapura Main Road and Banashankari in South Bangalore to Rajanakunte and Hoskote Town in North Bangalore, with upcoming projects in Thanisandra, Chandrapura, Hosur Road, Yelahanka and more. Call us at (+91 9513145554/+91 9739011616) or mail us at (marketing@gravityhomes.in) today."
    }, {
        question: "Can we book a site for your project?",
        answer: "Yes, we encourage site visits for all our projects so that you can feel assured of your home and take the experience firsthand. In fact, you can visit us anytime during the course of our developments and take a look at the progress at different stages. Please call us at (+91 9513145554/+91 9739011616) to know more about our upcoming projects and our team will schedule a site visit at your convenience. You can also mail us at (marketing@gravityhomes.in) to enquire for any further information about Gravity."
    }, {
        question: "What is the process for puchasing a home with Gravity?",
        answer: "We are excited to see your interest in purchasing your home with Gravity. Our easy 4-step process will ensure you a hassle-free buying experience. First, an Initial Consultation with our team to understand your requirements, preferences, and budget. This is followed by Property Selection where we will assist you in choosing a home that meets your lifestyle, then Scheduling Site Visit, we encourage site visits so that you can feel assured of your home and take the experience firsthand. Next, Legal Formalities where our legal experts will guide you through all necessary documentation, ensuring compliance and transparency, and creating personalised financial plans. Throughout the process, and after, our team will be available to provide you with all the guidance and support you need for a seamless and enjoyable purchase."
    }]
    return (
        <>
            <div className='FaqSection-main-parent' id='faqsection'>
                <div className='FaqSection-sub-parent'>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="Faq-left">
                                <h1>Frequently Asked Questions</h1>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="Faq-right">
                                <div className="Investfaq-parent">
                                    <div className='container'>
                                        <div className="row">
                                            {itemsToShow.map((item, index) => (
                                                <div key={index} className="col-md-12 p-0">
                                                    <div className="accordion-item">
                                                        <div
                                                            className="accordion-item-title"
                                                            onClick={() => handleToggle(index)}
                                                        >
                                                            <p>{item.question}</p>
                                                            <span className="icon">{openIndex === index ? '-' : '+'}</span>

                                                        </div>
                                                        {openIndex === index && (
                                                            <div className="accordion-item-desc">
                                                                {item.answer}

                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FaqSection
import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="Footer-main-parent">
        <div className="Footer-sub-parent">
          <div className="container-fluid">
            <div className="footer-main-content">
            <div className="Footer-content">
                <h3>Gravity Homes</h3>
                <p>
                  We take great pride in ensuring the satisfaction of our
                  customers.
                </p>
                <div className="Footer-social-links">
                  <div className="Footer-social-icons">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/GravityHomes/"
                    >
                      <img src="/facebook.svg" alt="" />
                    </a>
                  </div>
                  <div className="Footer-social-icons">
                    <a target="_blank" href="https://x.com/HomesGravity">
                      <img src="/twitter.svg" alt="" />
                    </a>
                  </div>
                  <div className="Footer-social-icons">
                    <a
                      target="_blank"
                      href="https://www.youtube.com/@GravityHomes"
                    >
                      <img src="/youtube.png" alt="" />
                    </a>
                  </div>
                  <div className="Footer-social-icons">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/gravityhomesin/"
                    >
                      <img src="/instagram.svg" alt="" />
                    </a>
                  </div>
                  <div className="Footer-social-icons">
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/gravity-homes-top-construction-company-in-bangalore/"
                    >
                      <img src="/linkedin.svg" alt="" />
                    </a>
                  </div>
                </div>
            </div>
            <div className="Footer-content">
                <div className="footer-project-financed-by">
                    <h2 className="finance-by-text">Project Financed by</h2>
                    <div className="financer-logo">
                        <img className="aditya-birla-image" src="/ABHFL_OG.png" alt="" />
                    </div>
                </div>
            </div>
            </div>
            
            <hr />
            <div className="Footer-page-links">
              <p>
                <Link to="/">HOME</Link>{" "}
              </p>
              <p>
                <Link to="/about">WHY GRAVITY</Link>{" "}
              </p>
              <p>
                <Link to="/project">PROJECTS</Link>{" "}
              </p>
              <p>
                <Link to="/blog">BLOG</Link>{" "}
              </p>
              <p>
                <Link to="/disclaimer">DISCLAIMER</Link>{" "}
              </p>
              <p className="rera-disclaimer-footer">
                <Link to="/rera-disclaimer">RERA DISCLAIMER</Link>{" "}
              </p>
            </div>
            <div className="copyrights-section d-flex justify-content-between">
              <p>© 2024 Gravity Homes. All rights reserved.</p>
              <p>
                Designed and developed by
                <a target="_blank" href="https://hirolainfotech.com/">
                  {" "}
                  Hirola InfoTech Solutions Pvt Ltd.
                </a>
              </p>
              <div className="d-flex gap-3">
                <p>
                  <Link to="/terms">Terms of Use</Link>
                </p>
                <p>
                  <Link to="/privacy-policy">Privacy-policy</Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

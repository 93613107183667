import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

const OngoingLayouts = () => {
    const propertyData = useSelector(state => state?.property?.propertyData);
    console.log("propertyData", propertyData);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [isMainImage, setIsMainImage] = useState(false);
    const [modalWidth, setModalWidth] = useState('100%');

    const handleImageClick = (imageSrc, isMain) => {
        setCurrentImage(imageSrc);
        setIsMainImage(isMain);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentImage('');
        setIsMainImage(false);
    };

    useEffect(() => {
        const updateModalWidth = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth <= 768) {
                setModalWidth('100%');
            } else if (screenWidth > 768 && screenWidth <= 1024) {
                setModalWidth('80%');
            } else {
                setModalWidth('65%');
            }
        };

        updateModalWidth();
        window.addEventListener('resize', updateModalWidth);

        return () => {
            window.removeEventListener('resize', updateModalWidth);
        };
    }, []);

    const layoutImages = propertyData?.mainImg || [];

    return (
        <>
            {layoutImages.length > 0 && (
                <>
                    <div className='Layouts-main-parent' id='Layouts'>
                        <div className='AssetsComponent-sub-parent'>
                            <h1>Layouts - </h1>
                            <div className='Layouts-sub-parent'>
                                <h2>Master plan</h2>
                                <div className='LayoutMain-image'>
                                    <img
                                        src={layoutImages[0].img}
                                        alt="Master Plan"
                                        onClick={() => handleImageClick(layoutImages[0]?.img, true)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="FloorPlans-main-parent">
                            <h2>Floor Plans</h2>
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                                loop={true}
                                autoplay={{
                                    duration:4500,
                                    disableOnInteraction:false
                                }}
                            >
                                <br /><br />
                                {layoutImages.slice(1).map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <div className='FloorPlans-image'>
                                            <img
                                                src={image?.img}
                                                alt={`Floor Plan ${index + 1}`}
                                                onClick={() => handleImageClick(image?.img, false)}
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </>
            )}
            <Modal
                visible={isModalVisible}
                footer={null}
                onCancel={handleCancel}
                width={modalWidth}
                bodyStyle={{ textAlign: 'center' }}
                className='layout-images-modal'
            >
                <div className="layout-modal-dispaly0n-modal">
                    <img className='Modal-inner-image' src={currentImage} alt="Current" />
                </div>
            </Modal>
        </>
    );
};

export default OngoingLayouts;

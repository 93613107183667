import React from 'react';
import { Modal } from 'antd';

const VideoModal = ({ isModalOpen, handleOk, handleCancel, videoUrl }) => {
    return (
        <>
        {isModalOpen && (
            <Modal
            // title="Video"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width="70%"
            centered
            bodyStyle={{ padding: 0 }}

            className='Video-modal'
        >
            {isModalOpen && (
                <div style={{ position: 'relative', paddingBottom: '56.25%', height: '80%' }}>
                    <iframe
                        src={`${videoUrl}?autoplay=1`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                        }}
                    ></iframe>
                </div>
            )}
        </Modal>
        )}
        </>
        
    );
};

export default VideoModal;

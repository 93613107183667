import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import previousProjects from "../JSON/PreviousProjects.json"
import { Navigation } from 'swiper/modules';

const PreviousProjects = () => {
    const swiperRef = useRef(null);

    const handlePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNext = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };
    const Previousprojectsdata = previousProjects;
    console.log("Previousprojectsdata",previousProjects);
    return (
        <>
            <div className="PreviousProjects-main-parent">
                <div className='OnGoingProjects-main-parent'>
                    <div className='OnGoingProjects-sub-parent'>
                        <div className='OngoingProjects-title'>
                            <div className='Emptydiv'>
                            </div>
                            <h1>Previous Projects</h1>
                        </div>
                        <div className='OnGoingProjects-desc'>
                            <p>Gravity Homes takes pride in a portfolio of completed projects that showcase our commitment to excellence, quality, and innovation. Each project reflects our dedication to creating exceptional living environments that blend luxury, functionality, and sustainability.</p>
                        </div>
                        <div className='OngoingProjects-MainContent'>
                            <Swiper
                                ref={swiperRef}
                                modules={[Navigation]}
                                spaceBetween={50}
                                slidesPerView={1}
                                loop={true}
                            >
                                {/* Slide 1 */}
                                {Previousprojectsdata?.length > 0 && Previousprojectsdata.map((item,index)=>(
                                    <SwiperSlide key={index}>

                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="OngoingProjects-MainContent-left">
                                                {console.log("itemitem",item)}
                                                <div className='OngoingProjects-image-div'>
                                                <h1>{index + 1 < 10 ? `0${index + 1}` : index + 1}</h1>
                                                    <div className='OngoingProjects-MainContent-image'>
                                                        <img src={item.img1} alt="" />
                                                    </div>
                                                    <div className='OngoingProjects-absolute-image'>
                                                        <img src={item.img2} alt="" />
                                                        <Link to={`/project/${item.slug}`}><button>View Project</button></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="OngoingProjects-MainContent-right">
                                                <h2>{item.title} </h2>
                                                <p>{item.location} </p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                )) }
                            </Swiper>
                            <div className='Previous-Next-buttons'>
                                <div className='Previous-Next-buttons-sub sub-div-2' onClick={handleNext}>
                                    <div className='emptyline'></div>
                                    <p>Next</p>
                                </div>
                                <div className='Previous-Next-buttons-sub' onClick={handlePrev}>
                                    <p>Prev</p>
                                    <div className='emptyline'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreviousProjects
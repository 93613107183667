import React, { useState } from 'react'
import { useSelector } from 'react-redux';

const PropertyVideo = () => {

    const propertyData = useSelector(state => state?.property?.propertyData)
    console.log("propertyData--", propertyData)
    const video = propertyData?.propertyVideo || []

    return (
        <>
            <div className='Location-main-parent' id='Location'>
                <div className='AssetsComponent-sub-parent'>
                    <h1>Video -  </h1>
                </div>
                <div className='Location-sub-parent'>
                    <div className='Location-map-parent'>
                        <iframe src={video} width="600" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PropertyVideo;
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'antd';
import ContactFormModal from '../Contactus/ContactFormModal';
const AskSection = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <div className='AskSection-parent'>
                <div className='AskSection-sub-parent' style={{ backgroundImage: `url('/Ask Section.png') ` }}>
                    <div className="container">
                        <div className='AskSection-content'>
                            <h2>We’re Here For You</h2>
                            <p>We are always available to answer your questions or concerns. Our customer service team is dedicated to providing prompt, professional, and friendly service, so if you ever need assistance, don't hesitate to reach out. You can contact us through our website, by phone, or via email, and we'll be happy to assist you.</p>
                            <Link to="#" onClick={showModal}>
                                <button>I have question</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <ContactFormModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </>
    )
}

export default AskSection
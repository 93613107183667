import React from 'react'
import '../Projects/Projects.css'
const ContactBanner = () => {
    return (
        <>
            <div className='ContactBanner-main-parent'>
                <div className='ProjectsBanner-main-parent'>
                    <div className='ProjectsBanner-sub-parent'>
                        <div className='ProjectsBanner-bg'>
                            <div className='ProjectsBanner-content'>
                                <h1>Contact Us</h1>
                            </div>
                        </div>
                        <div className='ProjectsBanner-Banner-text'>
                            <h2 className='contact-heading-main'>We love meeting new people and helping them.</h2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactBanner